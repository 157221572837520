import PropTypes from "prop-types";
import React from "react";
import { useField } from "formik";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 32,
    padding: 0,
    margin: 0,
    lineHeight: "15px",
    height: "100%"
  }),
  dropdownIndicator: () => null,
  indicatorSeparator: () => null,
  clearIndicator: (base) => ({
    ...base,
    padding: 0
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    lineHeight: "12px"
  }),
  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0
  }),
  singleValue: (base) => ({
    ...base,
    textOverflow: "clip",
    overflow: "visible",
    whiteSpace: "normal"
  })
};

const errorStyles = {
  ...customStyles,
  control: (base) => ({
    ...customStyles.control(base),
    border: "1px solid #DC2F23"
  })
};

const AutoComplete = ({
  name,
  options,
  isMultiSelect,
  placeholder,
  handleCreate,
  label,
  clearUserAddedValidations,
  showCreateNewOption,
  disable,
  updateChildDropdown,
  isParent,
  showDescription,
  onValueChange
}) => {
  const [field, meta, helpers] = useField(name);
  const styles = meta.error ? errorStyles : customStyles;
  return (
    <>
      {showCreateNewOption && !showDescription && (
        <CreatableSelect
          aria-label={label}
          styles={styles}
          isMulti={isMultiSelect}
          name={name}
          value={field.value}
          menuPlacement="auto"
          isClearable
          backspaceRemovesValue
          onChange={(value) => {
            helpers.setValue(value);
            clearUserAddedValidations();
            // works only if parent flag is set true
            if (isParent) {
              updateChildDropdown(value.value);
            }
          }}
          options={options}
          onBlur={() => helpers.setTouched(true)}
          onCreateOption={(val) => {
            handleCreate(val);
          }}
          placeholder={placeholder || `Select ${label}`}
        />
      )}
      {!showCreateNewOption && !showDescription && (
        <Select
          aria-label={label}
          styles={styles}
          isMulti={isMultiSelect}
          name={name}
          value={field.value}
          menuPlacement="auto"
          isClearable
          backspaceRemovesValue
          onChange={(value) => {
            helpers.setValue(value);
            clearUserAddedValidations();
            // works only if parent flag is set true
            if (isParent) {
              const val = value ? value.value : null;
              updateChildDropdown(val);
            }
            onValueChange(value?.value);
          }}
          options={options}
          onBlur={() => helpers.setTouched(true)}
          placeholder={placeholder || `Select ${label}`}
          isDisabled={disable}
        />
      )}
      {meta.error && meta.touched && (
        <div className="mt-1 text-danger">
          {meta.error.value ? meta.error.value : meta.error}
        </div>
      )}
    </>
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  isMultiSelect: PropTypes.bool,
  options: PropTypes.instanceOf(Array).isRequired,
  handleCreate: PropTypes.func.isRequired,
  clearUserAddedValidations: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showCreateNewOption: PropTypes.bool,
  label: PropTypes.string,
  disable: PropTypes.bool,
  updateChildDropdown: PropTypes.func,
  isParent: PropTypes.bool,
  showDescription: PropTypes.bool,
  onValueChange: PropTypes.func
};

AutoComplete.defaultProps = {
  isMultiSelect: false,
  placeholder: "",
  showCreateNewOption: true,
  label: "",
  disable: false,
  isParent: false,
  showDescription: false,
  updateChildDropdown: () => {},
  onValueChange: () => {}
};

export default AutoComplete;
