/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import TextBoxField from "../../shared/TextBoxField";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";

import ParConstants from "../../../common/ParConstants";
import RapConstants from "../../../common/RapConstants";
import CollapsibleCard from "../../shared/CollapsibleCard";
import ButtonGroup from "../../shared/ButtonGroup";

const ExternalFill = ({
  formikProps,
  validations,
  canModifyPARRecords,
  showDescription,
  workflowName,
  showFieldsReadOnly,
  hideFields
}) => (
  <CollapsibleCard
    className="highlighted-panel"
    title="External Fill Requirements"
  >
    <div className="row">
      <div className="col-md-3 mt-3">
        <TypeAheadBoxField
          formikProps={formikProps}
          suggestions={RapConstants.EMPLOYEE_TYPE_VALUES}
          fieldName="employee_type"
          label="Employee Type"
          disable={!canModifyPARRecords}
          showDescription={showDescription}
        />
      </div>
      {/* Show fields for Non Global Employee Type */}
      {formikProps.values?.employee_type &&
        formikProps.values.employee_type?.value !==
          RapConstants.EMPLOYEE_TYPE_VALUES[1] && (
          <>
            <div className="col-md-3 mt-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={validations.locationList || []}
                fieldName="fill_location"
                label="Fill Location"
                disable={!canModifyPARRecords}
                showDescription={showDescription}
              />
            </div>
            <div className="col-md-3 mt-3">
              <TypeAheadBoxField
                formikProps={formikProps}
                suggestions={ParConstants.LOCATION_REASON}
                fieldName="location_reason"
                label="Location Reason"
                disable={!canModifyPARRecords}
                showDescription={showDescription}
              />
            </div>
            {/* isExempt is valid only for non-global employees */}
            {formikProps.values?.employee_type &&
              formikProps.values.employee_type?.value !==
                RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
              !hideFields.is_exempt &&
              !showFieldsReadOnly.is_exempt && (
                <div className="col-sm-2 mt-3">
                  <Field name="is_exempt">
                    {({ meta }) => (
                      <ButtonGroup
                        metaObj={meta}
                        fieldName="is_exempt"
                        fieldValue={formikProps.values.is_exempt}
                        label="Exempt"
                        buttonValues={ParConstants.BOOLEAN_VALUES}
                        setButtonValue={(value) => {
                          formikProps.setFieldValue("is_exempt", value);
                        }}
                        inError={!!meta.error}
                        isDisabled={!canModifyPARRecords}
                        isRequired
                      />
                    )}
                  </Field>
                </div>
              )}
            {formikProps.values?.employee_type &&
              formikProps.values.employee_type?.value !==
                RapConstants.EMPLOYEE_TYPE_VALUES[1] &&
              !hideFields.is_exempt &&
              showFieldsReadOnly.is_exempt && (
                <div className="col-sm-2 mt-3">
                  <label htmlFor="btnGrpForm-is_exempt">
                    Exempt
                    <span className="red-text"> *</span>
                  </label>
                  <div id="btnGrpForm-is_exempt" className="form-description">
                    <strong>{formikProps.values.is_exempt}</strong>
                  </div>
                </div>
              )}
          </>
        )}
      {/* Show fields for Global Employee Type */}
      {formikProps.values?.employee_type &&
        formikProps.values.employee_type?.value ===
          RapConstants.EMPLOYEE_TYPE_VALUES[1] && (
          <>
            {!hideFields.partner && (
              <div className="col-md-3 mt-3">
                <TypeAheadBoxField
                  formikProps={formikProps}
                  suggestions={
                    workflowName === ParConstants.PAR_WORKFLOW.OPEN_REQUISITION
                      ? ParConstants.PARTNERS_OPEN_REQ
                      : ParConstants.PARTNERS
                  }
                  fieldName="partner"
                  label="Partner"
                  disable={!canModifyPARRecords}
                  showDescription={showFieldsReadOnly.partner}
                />
              </div>
            )}
            {!hideFields.partner_cost && (
              <div className="col-md-3 mt-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="partner_cost"
                  label="Partner Cost"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.partner_cost}
                  disable={!canModifyPARRecords}
                />
              </div>
            )}
          </>
        )}
    </div>
  </CollapsibleCard>
);

ExternalFill.propTypes = {
  formikProps: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      is_exempt: PropTypes.string,
      location_reason: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      fill_location: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      partner: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    })
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array)
  }).isRequired,
  workflowName: PropTypes.string,
  showFieldsReadOnly: PropTypes.shape({
    allocClient: PropTypes.bool,
    allocLOB: PropTypes.bool,
    allocRateCard: PropTypes.bool,
    allocVal: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    is_exempt: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    allocRateCard: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    is_exempt: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool
  })
};

ExternalFill.defaultProps = {
  canModifyPARRecords: false,
  showDescription: false,
  workflowName: null,
  showFieldsReadOnly: {},
  hideFields: {}
};

export default ExternalFill;
