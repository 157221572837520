/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import OktaLogout from "../OktaLogout";
import Constants from "../common/Constants";
import Cookies from "../helpers/Cookies";
import logo from "../assets/RAP_logo.png";
import altlogo from "../assets/black.png";

import security from "../services/Security";
import UserContext from "../store/UserContext";
import api from "../services/UserAdminApi";
import MenuItems from "./MenuItems";
import NotificationBell from "./shared/NotificationBell";

class TopNav extends React.PureComponent {
  componentDidMount() {
    const defaultCountry = JSON.parse(
      localStorage.getItem(Constants.FINOPS_COUNTRY)
    );
    if (this.isDropdownVisible() && !defaultCountry?.countryname) {
      this.loadUserCountry(defaultCountry);
    }
  }

  isDropdownVisible = () =>
    localStorage.getItem(Constants.FINOPS_MULTIPLE_COUNTRY) === "true";

  loadUserCountry = async (defaultCountry) => {
    const { setDefaultCountry, setIsOpenModal } = this.context;
    try {
      const userId = window?.userSession?.userId;
      const userCountryResult = await api.getCountriesByUser(userId);
      if (defaultCountry?.countryid) {
        const country = userCountryResult.find(
          (c) => c.countryid === defaultCountry?.countryid
        );
        localStorage.setItem(Constants.FINOPS_COUNTRY, JSON.stringify(country));
        setDefaultCountry({ ...country });
      } else {
        setIsOpenModal(true);
      }
    } catch (ex) {
      toast.error(
        `There was an error in loading the user's country data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  routeToReport = () => {
    const sessionKey = Cookies.getCookie(Constants.FINOPS_SESSION_KEY);
    if (sessionKey) {
      window.open("/#/embed", "_blank");
    }
  };

  routeToCTS = () => {
    const sessionKey = Cookies.getCookie(Constants.FINOPS_SESSION_KEY);
    if (sessionKey) {
      window.location.href = "/#/scenarios";
    }
  };

  routeToPAR = () => {
    const sessionKey = Cookies.getCookie(Constants.FINOPS_SESSION_KEY);
    if (sessionKey) {
      window.location.href = "/#/par";
    }
  };

  openCountrySelectionModal = () => {
    this.context.setIsOpenModal(true);
  };

  render() {
    // Get email address from the user session
    const emailAddress =
      window.userSession && window.userSession.email
        ? window.userSession.email
        : "";

    // Get the roles
    const canEditRAPData = security.canEditRAPData();
    const canViewRAPReports = security.canViewRAPReports();
    const canEditCTSData = security.canEditCTSData();
    const canLoadCTSData = security.canLoadCTSData();
    const isAdmin = security.isAdmin();
    const isRMOrAdmin = security.canModifyRapRecords();
    const canEditPARData = security.canEditPARData();

    return (
      <div className="navbar-container">
        <div id="navbar" className="navbar" role="navigation">
          <div className="row">
            <a href="/#/" className="home-icon-section">
              <i className="fa fa-home" />
            </a>{" "}
            <div
              className={
                canEditRAPData ? "app-header-name" : "app-header-name-alt"
              }
            >
              <a href="/#/">
                <img
                  src={canEditRAPData ? logo : altlogo}
                  height="45"
                  className="img-responsive"
                  alt="dentsu logo"
                />
              </a>
            </div>
            {canViewRAPReports && (
              <div className="app-header-menuitem">
                <a onClick={() => this.routeToReport()}>RAP Reports</a>
              </div>
            )}
            {canEditPARData && (
              <div className="app-header-menuitem">
                <a onClick={() => this.routeToPAR()}>Position Action Request</a>
              </div>
            )}
            {canLoadCTSData && canEditCTSData && (
              <div className="app-header-menuitem">
                <a onClick={() => this.routeToCTS()}>Cost To Serve</a>
              </div>
            )}
            {isRMOrAdmin && (
              <div className="app-header-menuitem">
                <a href="/#/data-management">RAP Data Management</a>
              </div>
            )}
            {isAdmin && (
              <div className="app-header-menuitem">
                <ul className="nav-menu">
                  {Constants.FINOPS_ADMIN_MENU.map((menu) => (
                    <MenuItems items={menu} key={menu.title} />
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="nav navbar-right">
            {this.isDropdownVisible() && (
              <div>
                <span onClick={this.openCountrySelectionModal}>
                  {`${this.context?.defaultCountry?.countryname || ""} `}
                  <i className="fas fa-chevron-down" />
                </span>
              </div>
            )}
            <div className="nav-user">
              <strong>{emailAddress}</strong>
            </div>
            {canEditPARData && (
              <NotificationBell
                hasNotification={this.props.hasNotification}
                onBellIconClick={this.props.onBellIconClick}
              />
            )}
            <a
              alt="Change Password"
              className="nav-logout"
              href="https://myapps.dentsu.com/signin/forgot-password"
              target="_blank"
              title="Change Password"
              rel="noreferrer"
            >
              <i className="fas fa-key" />
            </a>
            <a
              alt="Report Bug"
              href="https://dentsu-msl.atlassian.net/servicedesk/customer/portal/18/group/71/create/139"
              target="_blank"
              title="Report Bug"
              rel="noreferrer"
              className="nav-bugreport"
            >
              <i className="fas fa-bug fa-lg" />
            </a>
            <OktaLogout />
          </div>
        </div>
      </div>
    );
  }
}

TopNav.contextType = UserContext;

TopNav.propTypes = {
  hasNotification: PropTypes.bool,
  onBellIconClick: PropTypes.func
};

TopNav.defaultProps = {
  hasNotification: false,
  onBellIconClick: () => {}
};

export default TopNav;
