/* eslint-disable no-return-assign */
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { isBefore } from "date-fns";
import * as Yup from "yup";
import ParForm from "./ParForm";
import parApi from "../../../services/ParApi";
import rapApi from "../../../services/RapApi";
import ParConstants from "../../../common/ParConstants";
import LoadingSpinner from "../../shared/LoadingSpinner";
import "../../../styles/shared/collapsible-card.scss";

const CreatePAR = () => {
  let location = useLocation();

  // Fetch parRequestId from route parameters
  const { parRequestIdParam } = useParams();
  const navigate = useNavigate();

  const [validations, setValidations] = useState(
    location.state && location.state.validations
  );
  const [initialData, setInitialData] = useState(null);
  const [parType, setParType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const [skipValidations, setSkipValidations] = useState([
    "par_request_id",
    "current_queue_id",
    "business_title",
    "par_billable",
    "attachment_paths",
    "current_performance_rating",
    "target_effective_date",
    "comment",
    "employee_workdayid",
    "employee_workdaytitle",
    "employee_location",
    "submitter",
    "par_position_title",
    "par_axcompany",
    "par_bpc",
    "par_brand",
    "par_payroll_company",
    "current_queue_name",
    "related_pars",

    // TODO: Remove this from here once we get clarity on this data field
    "job_code"
  ]);
  const isSubmitterRole =
    window.userSession.parPermissions.permission_name ===
    ParConstants.PAR_PERMISSIONS_MAPPING.PAR_ClientBrandSubmitter;

  const prepareData = (record) => {
    let newData = {};
    if (record) {
      newData = {
        existing_msl_pin: record.mslPin,
        summary_department: record.summaryDepartment,
        serviceline_department: record.serviceLineDepartment,
        par_level: record.level,
        employee_type: record.employeeType,
        fill_location: record.location,
        par_billable: record.billable === "billable" ? "Yes" : "No",
        is_exempt: "",
        profit_center: record.payrollProfitCenter
      };

      if (record.workdayId && record.workdayId !== "") {
        newData.employee_workdayid = record.workdayId;
        newData.employee_name = `${record.givenName} ${record.familyName}-${record.workdayId}`;
        newData.employee_workdaytitle = record.workdayTitle;
        newData.employee_location = record.location;
        newData.employee_type = record.employeeType;
      }

      // Skip eliminated allocations, only show active or tbh
      const allocations = record.allocations.filter(
        (a) => a.status !== "Eliminated"
      );
      if (allocations && allocations.length > 0) {
        newData.allocations = allocations.map((x) => ({
          allocation: x.allocation,
          client_brand: x.client,
          client_lob: x.clientLob,
          rate_card: x.rateCard,
          reconcilable: x.reconcilable,
          status: x.status,
          disableAllocation: true
        }));
      }
    }
    return newData;
  };

  const loadInitialData = async (isRefresh = false, inputPAR = null) => {
    setIsLoading(true);
    if (!isRefresh) {
      setLoadingMsg("Please wait, PAR Details are being loaded...");
    } else {
      setLoadingMsg("Please wait, re-loading saved PAR details");
    }
    if (location.state || parRequestIdParam || inputPAR) {
      let parRequestId = inputPAR;
      let mslData = null;
      let workflowType = null;
      if (location && location.state && !inputPAR) {
        parRequestId = location.state.parRequestId;
        mslData = location.state.mslData;
        workflowType = location.state.workflowType;
      } else if (parRequestIdParam && !inputPAR) {
        parRequestId = parRequestIdParam;
      }

      // If its a details screen, par request id will be present
      if (parRequestId) {
        const response = await parApi.getPARRequest({
          par_request_id: parRequestId
        });
        if (!response.par_request_id) {
          // Show error page in case of an invalid URL
          navigate("/error", {
            state: {
              errorTitle: "Invalid URL",
              errorMessage:
                "Invalid PAR Ticket Link! Please fix the URL and reload the page."
            }
          });
        }
        setInitialData(response);
        // Always require a comment if a request is in a specific QUEUE
        if (response.current_queue_id) {
          setSkipValidations(
            Object.keys(ParConstants.FORM_FIELDS).filter((x) => x !== "comment")
          );
        }
        // Get workflow info to be set as PAR Type
        const workflowInfo = {
          workflow_id: response.workflow_id,
          name: response.workflow_name
        };
        setParType(workflowInfo);
      } else if (mslData && mslData.mslPin) {
        // If create par is called from existing PIN
        const response = await rapApi.getRecord(mslData.mslPin);
        const inputData = prepareData(response);
        setInitialData(inputData);
        setParType(workflowType);
      }
    }
    setIsLoading(false);
  };

  const loadValidationValues = async () => {
    try {
      await rapApi
        .getValidations()
        .then((response) => setValidations(response));
    } catch (ex) {
      console.log(ex);
    }
  };

  const getValidationSchema = () => {
    const errorMsg = "Please enter a value";
    const errorMsgSelect = "Please select a value";
    const schemaObj = {};

    Object.keys(ParConstants.FORM_FIELDS).forEach((field) => {
      if (!schemaObj[field]) {
        if (field === "allocations") {
          // Only validatate client and allocation, and do not validate the fields
          schemaObj[field] = Yup.array()
            .of(
              Yup.object().shape({
                allocation: Yup.number()
                  .min(0, "should be greater than 0")
                  .max(1, "should not be more than 1")
                  .typeError("should be a number")
                  .required(errorMsg),
                client_brand: Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect),
                    value: Yup.string().required(errorMsgSelect)
                  })
                  .required(errorMsgSelect)
                  .nullable(),
                rate_card: Yup.number()
                  .typeError("Rate Card should be a number")
                  .test(
                    "rate-card-req",
                    "Please enter a valid value for Rate Card",
                    (value) => {
                      let isValid = true;
                      // An empty record is allowed for a submitter role
                      if (!value || value === "") {
                        isValid = !!isSubmitterRole;
                      }
                      return isValid;
                    }
                  ),
                reconcilable: Yup.string().test(
                  "reconcilable-req",
                  "Select a value for Reconcilable",
                  (value) => {
                    let isValid = true;
                    // An empty record is allowed for a submitter role
                    if (!value || value === "") {
                      isValid = !!isSubmitterRole;
                    }
                    return isValid;
                  }
                )
              })
            )
            .test(
              "alloc-req",
              "Please add at least one allocation",
              (allocArr) => allocArr.length >= 1
            );
        } else if (field === "partner") {
          schemaObj[field] = Yup.object().when(
            ["fill_type.value", "employee_type.value"],
            {
              is: (filltypeval, emptypeval) =>
                filltypeval &&
                filltypeval === "External Fill" &&
                emptypeval &&
                emptypeval === "Global" &&
                !isSubmitterRole,
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            }
          );
        } else if (field === "profit_center") {
          schemaObj[field] = Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string()
            })
            .test(
              "profitcenter-req",
              "Select a value for Profit Center",
              (profitCenterVal) => {
                let isValid = true;
                // An empty record is allowed for a submitter role
                if (
                  !profitCenterVal ||
                  !profitCenterVal.value ||
                  profitCenterVal.value === ""
                ) {
                  isValid = !!isSubmitterRole;
                }
                return isValid;
              }
            )
            .nullable();
        } else if (field === "fill_date" && !skipValidations.includes(field)) {
          schemaObj[field] = Yup.string()
            .test(
              "date-format",
              "Date should be in MM/dd/yyyy format",
              (value) => {
                if (!value || value === "") {
                  return true;
                }
                const formattedDate = new Date(value).toLocaleDateString(
                  "en-US"
                );
                // If its a correctly formatted date
                return /^([1-9]|1[012])[- /.]([1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d$/.test(
                  formattedDate
                );
              }
            )
            .test(
              "future date",
              `${field} should be a future date`,
              (value) => {
                if (field === "fill_date") {
                  if (!value || value === "") {
                    return true;
                  }
                  return !isBefore(new Date(value), new Date(new Date()));
                }
                return true;
              }
            )
            .test("past date", `should be a past date`, (value) => {
              if (
                field === "date_of_lastpromotion" ||
                field === "date_of_last_compensation_change"
              ) {
                if (!value || value === "") {
                  return true;
                }

                return isBefore(new Date(value), new Date(new Date()));
              }
              return true;
            })
            .required(`is a required field`)
            .nullable();
        } else if (
          (field === "date_of_lastpromotion" ||
            field === "date_of_last_compensation_change") &&
          !skipValidations.includes(field)
        ) {
          schemaObj[field] = Yup.string()
            .test(
              "date-format",
              "Date should be in MM/dd/yyyy format",
              (value) => {
                if (!value || value === "") {
                  return true;
                }
                const formattedDate = new Date(value).toLocaleDateString(
                  "en-US"
                );
                // If its a correctly formatted date
                return /^([1-9]|1[012])[- /.]([1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d$/.test(
                  formattedDate
                );
              }
            )
            .test("past date", `should be a past date`, (value) => {
              if (
                field === "date_of_lastpromotion" ||
                field === "date_of_last_compensation_change"
              ) {
                if (!value || value === "") {
                  return true;
                }
                return isBefore(new Date(value), new Date(new Date()));
              }
              return true;
            })
            .nullable();
        } else if (field === "attachment_paths") {
          schemaObj[field] = Yup.string().when("allocations", {
            is: (value) =>
              value.length > 1 &&
              [
                ...new Set(
                  value
                    .filter((x) => x.client_brand)
                    .map((x) => x.client_brand.value.split(" - ")[1])
                )
              ].length > 1,
            then: Yup.string()
              .required(
                "Allocations have been added for different brands. Please attach files with approvals from other brands"
              )
              .nullable(),
            otherwise: Yup.string().nullable()
          });
        } else if (
          field === "current_base_salary" ||
          field === "requested_offer"
        ) {
          schemaObj[field] = Yup.number().min(0, "Value must be non-negative");
        } else if (
          ParConstants.LIST_FIELDS.includes(field) &&
          !skipValidations.includes(field)
        ) {
          // Skip validations for some fields that are not required
          if (field === "employee_type") {
            schemaObj[field] = Yup.object().when("fill_type.value", {
              is: "External Fill", // condition based on the value property of the object
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            });
          } else if (field === "fill_location" || field === "location_reason") {
            schemaObj[field] = Yup.object().when(
              ["fill_type.value", "employee_type.value"],
              {
                is: (filltypeval, emptypeval) =>
                  filltypeval &&
                  filltypeval === "External Fill" &&
                  emptypeval &&
                  emptypeval !== "Global",
                then: Yup.object()
                  .shape({
                    label: Yup.string().required(errorMsgSelect).nullable(),
                    value: Yup.string().required(errorMsgSelect).nullable()
                  })
                  .required(errorMsgSelect)
                  .nullable(),
                otherwise: Yup.object()
                  .shape({
                    label: Yup.string().nullable(),
                    value: Yup.string().nullable()
                  })
                  .nullable()
              }
            );
          } else if (field === "employee_name") {
            schemaObj[field] = Yup.object().when("fill_type.value", {
              is: (value) =>
                [
                  "Internal Fill, Lateral Move",
                  "Internal Fill, Promotion"
                ].includes(value),
              then: Yup.object()
                .shape({
                  label: Yup.string().required(errorMsgSelect).nullable(),
                  value: Yup.string().required(errorMsgSelect).nullable()
                })
                .required(errorMsgSelect)
                .nullable(),
              otherwise: Yup.object()
                .shape({
                  label: Yup.string().nullable(),
                  value: Yup.string().nullable()
                })
                .nullable()
            });
          } else if (field === "cc_emails") {
            schemaObj[field] = Yup.array()
              .of(
                Yup.object().shape({
                  label: Yup.string(),
                  value: Yup.string()
                })
              )
              .test(
                "email",
                "please enter valid email address(es)",
                (value) => {
                  let anyobj = [];
                  if (!value) {
                    return true;
                  }
                  if (value && value.length > 0) {
                    const regex =
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
                    anyobj = value.filter((x) => regex.test(x && x.value));
                  }
                  return anyobj.length === value.length;
                }
              )
              .nullable();
          } else {
            schemaObj[field] = Yup.object()
              .shape({
                label: Yup.string().required(errorMsgSelect),
                value: Yup.string().required(errorMsgSelect)
              })
              .required(errorMsgSelect)
              .nullable();
          }
        } else if (field === "partner_cost") {
          schemaObj[field] = Yup.number().when(
            ["fill_type.value", "employee_type.value"],
            {
              is: (filltype, emptype) =>
                filltype &&
                filltype === "External Fill" &&
                emptype &&
                emptype === "Global" &&
                !isSubmitterRole,
              then: Yup.number()
                .typeError("should be a number")
                .required(errorMsg)
            }
          );
        } else if (field === "is_exempt") {
          schemaObj[field] = Yup.string().when(
            ["fill_type.value", "employee_type.value"],
            {
              is: (filltype, emptype) =>
                filltype &&
                filltype === "External Fill" &&
                emptype &&
                emptype !== "Global" &&
                parType &&
                [
                  ParConstants.PAR_WORKFLOW.BACKFILL_REQUISITION,
                  ParConstants.PAR_WORKFLOW.OPEN_REQUISITION_TBH
                ].includes(parType.name),
              then: Yup.string().trim().required(errorMsgSelect)
            }
          );
        } else if (field === "requisition_id") {
          const reqIdWorkflows = [
            ParConstants.PAR_WORKFLOW.BACKFILL_REQUISITION,
            ParConstants.PAR_WORKFLOW.OPEN_REQUISITION_TBH,
            ParConstants.PAR_WORKFLOW.OPEN_REQUISITION
          ];
          const queueNames = [
            ParConstants.PAR_QUEUE.HR_ACTION,
            ParConstants.PAR_QUEUE.RM_ACTION
          ];
          schemaObj[field] = Yup.string().when(
            ["employee_type.value", "current_queue_name"],
            {
              is: (emptype, queuename) =>
                parType &&
                reqIdWorkflows.includes(parType.name) &&
                queuename &&
                queueNames.includes(queuename) &&
                emptype &&
                emptype !== "Global",
              then: Yup.string()
                .trim()
                .required("Please enter a Requisition ID")
                .max(10, "Requisition ID must not be more than 10 characters.")
            }
          );
        } else if (field === "mslPin") {
          schemaObj[field] = Yup.string()
            .trim()
            .test(
              "only-alphabets",
              "Only uppercase letters and numbers are allowed.",
              (value) => {
                // An empty record is allowed
                if (!value || value === "") {
                  return true;
                }
                // Only upper case letters and numbers are allowed
                return /^[A-Z-0-9]+$/.test(value);
              }
            );
        } else if (!skipValidations.includes(field)) {
          // Skip validations for some fields that are not required
          schemaObj[field] = Yup.string().required(errorMsg);
        }
        if (["pinApproved", "dvSga"].includes(field)) {
          schemaObj[field] = Yup.string().trim().required(errorMsg);
        }
      }
    });
    return Yup.object(schemaObj);
  };

  const refreshFormData = (parRequestId) => {
    loadInitialData(true, parRequestId);
    if (!validations) {
      // Reloading validation values if not passed
      loadValidationValues();
    }
  };

  useEffect(() => {
    loadInitialData();
    if (!validations) {
      // Reloading validation values if not passed
      loadValidationValues();
    }
  }, [parRequestIdParam]);

  return (
    <div className="par-container">
      {(!validations || isLoading) && (
        <LoadingSpinner status="Loading" textMessage={loadingMsg} />
      )}
      {validations && !isLoading && (
        <ParForm
          getValidationSchema={getValidationSchema}
          skipValidations={skipValidations}
          validations={validations}
          initialData={initialData}
          parType={parType}
          parAvailablePins={location.state && location.state.parAvailablePins}
          refreshForm={refreshFormData}
        />
      )}
    </div>
  );
};

export default CreatePAR;
