/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import CollapsibleCard from "../../shared/CollapsibleCard";
import RapConstants from "../../../common/RapConstants";
import ParConstants from "../../../common/ParConstants";
import InternalFill from "./InternalFill";
import ExternalFill from "./ExternalFill";
import TextBoxField from "../../shared/TextBoxField";
import DatePickerBoxField from "../../shared/DatePickerBoxField";

const RequisitionInstructions = ({
  formikProps,
  canModifyPARRecords,
  availablePINs,
  validations,
  showDescription,
  workflowName,
  showFieldsReadOnly,
  hideFields
}) => {
  const updateFillTypeField = (fieldList, formProps, initialData = false) => {
    fieldList.forEach((field) => {
      const value = initialData
        ? formProps.initialValues[field]
        : formProps.values[field];

      if (initialData && value !== undefined) {
        // If initialData is true and value exists, set the initial value
        formProps.setFieldValue(field, value);
      } else if (value !== undefined) {
        // Otherwise, reset the field
        const newValue =
          typeof value === "object" && !(value instanceof Date) ? null : "";
        formProps.setFieldValue(field, newValue);
      }
    });
  };

  const handleFillTypeSelection = (fillType, formProps) => {
    const { FILL_TYPES, EXTERNAL_FILL_FIELDS, INTERNAL_FILL_FIELDS } =
      ParConstants;

    // Reset external fill type if internal fill type selected and vice versa
    const fieldsToReset =
      fillType === FILL_TYPES[0]
        ? INTERNAL_FILL_FIELDS
        : fillType === FILL_TYPES[1] || fillType === FILL_TYPES[2]
        ? EXTERNAL_FILL_FIELDS
        : [];

    updateFillTypeField(fieldsToReset, formProps);

    // If we re-selecting existin fill type then again populating with inital value
    if (formProps.initialValues?.fill_type?.value === fillType) {
      const populateFields =
        fillType === FILL_TYPES[0]
          ? EXTERNAL_FILL_FIELDS
          : INTERNAL_FILL_FIELDS;
      updateFillTypeField(populateFields, formProps, true);
    }
  };

  return (
    <CollapsibleCard title="Requisition Instructions">
      <div className="row">
        <div className="mt-3 col-md-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={ParConstants.FILL_TYPES}
            fieldName="fill_type"
            label="Fill Type"
            disable={!canModifyPARRecords}
            showDescription={showDescription}
            onValueChange={(value) =>
              handleFillTypeSelection(value, formikProps)
            }
          />
        </div>
        <div className="mt-3 col-md-3">
          <DatePickerBoxField
            className="ml-0"
            formikProps={formikProps}
            label="Target Fill Date"
            fieldName="fill_date"
            disable={!canModifyPARRecords}
            showDescription={showDescription}
          />
        </div>
        <div className="mt-3 col-md-3">
          <TypeAheadBoxField
            formikProps={formikProps}
            suggestions={availablePINs?.map((x) => x.employeeName) || []}
            fieldName="hiring_manager"
            placeholder="Search by Name or Workday ID"
            label="Direct Manager"
            disable={!canModifyPARRecords}
            showDescription={showDescription}
          />
        </div>
      </div>

      {formikProps.values.fill_type &&
        formikProps.values.fill_type?.value === ParConstants.FILL_TYPES[0] && (
          <ExternalFill
            validations={validations || []}
            formikProps={formikProps}
            canModifyPARRecords={canModifyPARRecords}
            showDescription={showDescription}
            workflowName={workflowName}
            showFieldsReadOnly={showFieldsReadOnly}
            hideFields={hideFields}
          />
        )}

      {formikProps.values.fill_type &&
        (formikProps.values.fill_type?.value === ParConstants.FILL_TYPES[1] ||
          formikProps.values.fill_type?.value ===
            ParConstants.FILL_TYPES[2]) && (
          <InternalFill
            formikProps={formikProps}
            canModifyPARRecords={canModifyPARRecords}
            availablePINs={availablePINs || []}
            showDescription={showDescription}
            showFieldsReadOnly={showFieldsReadOnly}
            hideFields={hideFields}
          />
        )}
      {/** Only show this Requisition Row with BPC, AX Company etc for non-Global employees * */}
      {formikProps.values?.employee_type &&
        formikProps.values.employee_type?.value !==
          RapConstants.EMPLOYEE_TYPE_VALUES[1] && (
          <div className="row">
            {!hideFields.par_brand && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_brand"
                  label="Brand"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_brand}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
            {!hideFields.par_axcompany && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_axcompany"
                  label="AX Company"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_axcompany}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
            {!hideFields.par_payroll_company && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_payroll_company"
                  label="Payroll Company"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_payroll_company}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
            {!hideFields.par_bpc && (
              <div className="mt-3 col-md-3">
                <TextBoxField
                  formikProps={formikProps}
                  fieldName="par_bpc"
                  label="BPC"
                  className="ml-1"
                  showDescription={showFieldsReadOnly.par_bpc}
                  disable={!canModifyPARRecords}
                  isOptional
                />
              </div>
            )}
          </div>
        )}
    </CollapsibleCard>
  );
};

RequisitionInstructions.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      fill_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      employee_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    initialValues: PropTypes.shape({
      fill_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  showDescription: PropTypes.bool,
  canModifyPARRecords: PropTypes.bool,
  availablePINs: PropTypes.instanceOf(Array),
  validations: PropTypes.shape({
    clientList: PropTypes.instanceOf(Array),
    clientLobList: PropTypes.instanceOf(Array),
    payrollCompanyList: PropTypes.instanceOf(Array),
    payrollDepartmentList: PropTypes.instanceOf(Array),
    payrollProfitCenterList: PropTypes.instanceOf(Array),
    serviceLineDepartmentList: PropTypes.instanceOf(Array),
    summaryDepartmentList: PropTypes.instanceOf(Array),
    tbhCategoryList: PropTypes.instanceOf(Array),
    roleTitleList: PropTypes.instanceOf(Array),
    locationList: PropTypes.instanceOf(Array),
    levelList: PropTypes.instanceOf(Array)
  }).isRequired,
  workflowName: PropTypes.string,
  showFieldsReadOnly: PropTypes.shape({
    allocClient: PropTypes.bool,
    allocLOB: PropTypes.bool,
    allocRateCard: PropTypes.bool,
    allocVal: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool,
    par_brand: PropTypes.bool,
    par_axcompany: PropTypes.bool,
    par_payroll_company: PropTypes.bool,
    par_bpc: PropTypes.bool
  }),
  hideFields: PropTypes.shape({
    allocRateCard: PropTypes.bool,
    allocReconcilable: PropTypes.bool,
    profit_center: PropTypes.bool,
    partner: PropTypes.bool,
    partner_cost: PropTypes.bool,
    par_brand: PropTypes.bool,
    par_axcompany: PropTypes.bool,
    par_payroll_company: PropTypes.bool,
    par_bpc: PropTypes.bool
  })
};

RequisitionInstructions.defaultProps = {
  availablePINs: [],
  canModifyPARRecords: false,
  showDescription: false,
  workflowName: null,
  showFieldsReadOnly: {},
  hideFields: {}
};

export default RequisitionInstructions;
