import Constants from "../common/Constants";
import RapConstants from "../common/RapConstants";
import baseApi from "./BaseApi";

/**
 * RAP API wrapper.
 *
 * NOTE: central handling of the API done through this file as a central way to have tight
 * control over what the rest of the UI code has to deal with. For example, if a property
 * name in a result needs to be changed, and the server side doesn't support it yet, this
 * API file can update the results so the UI itself can move on without final support from
 * the server. This would be similar property control that the property mappings in
 * RapConstants would allow, but without the extra double handling at each point of usage.
 *
 * This will also be the place where we're injecting the security headers.
 */

/**
 * Abstract the core HTTP call with specific verb functions.
 */
const postRequest = (path, data) =>
  baseApi.baseRequest("POST", RapConstants.API_ROOT_URL + path, data);
const getRequest = (path) =>
  baseApi.baseRequest("GET", RapConstants.API_ROOT_URL + path);

function getCountry() {
  // Get the countryid from localStorage / sesssion
  const defaultCountry = JSON.parse(
    localStorage.getItem(Constants.FINOPS_COUNTRY)
  );
  return defaultCountry?.countryid;
}

/**
 * Handler function for getting the workflow list.
 * Kind of an example that does some superfluous handling just to show how this API
 * file can help the UI stay in charge of what it's dealing with in the rest of the code.
 */
async function getWorkflowList(request) {
  const countryid = getCountry();
  if (!request) {
    request = { countryid };
  } else {
    request.countryid = countryid;
  }
  const { workflows } = await postRequest("/getWorkflowList", request || {});

  const propConversions = [
    // [ fromProp, toProp ]
    ["reallySillyPropertyName", "nicerPropertyName"]
  ];

  if (workflows && workflows.length) {
    if (propConversions.length) {
      // any transforming we might want to do because the server is crazy...
      // (could also deal with unnecessary nested objects, etc)
      workflows.forEach((record) => {
        record.workdayId = record.workdayId ? record.workdayId + "" : null;
        record.mslPin = record.mslPin ? record.mslPin + "" : 1 + "";

        propConversions.forEach((conversion) => {
          if (record[conversion[0]]) {
            record[conversion[1]] = record[conversion[0]];
            delete record[conversion[0]];
          }
        });
      });
    }

    // make up the display name from parts
    return workflows.map((v) =>
      Object.assign(v, { displayName: `${v.familyName}, ${v.givenName}` })
    );
  }
  return [];
}

async function getRecord(recordId) {
  const countryid = getCountry();
  const response = await postRequest("/getRecord", { recordId, countryid });

  const { record } = response;

  if (record.allocations) {
    record.allocations.forEach((a) => {
      if (a.allocation) {
        let s = a.allocation + "";
        let p = s.indexOf(".");
        if (p > -1) {
          if (p === s.length - 1) s += "00";
          else if (p === s.length - 2) s += "0";

          if (p === 0) s = "0" + s;
          // if (s.charAt(0) === '0') s = s.substring(1);

          a.allocation = s;
        }
      }
    });
  }

  return record;
}

async function getRecordHistory(recordId) {
  const countryid = getCountry();
  const response = await postRequest("/getRecordHistory", {
    recordId,
    countryid
  });
  // Returns an array of history records
  return response.history;
}

async function getValidations() {
  const countryid = getCountry();
  const response = await postRequest("/getValidations", { countryid });
  return response.validations;
}

async function validateSession(sessionKey, emailAddress) {
  return postRequest("/validateSession", { sessionKey, emailAddress });
}

async function sendLoginEmail(email) {
  let staging = window.isStaging ? true : undefined; // eslint-disable-line
  if (!window.isProd && !staging) {
    let loc = document.location + "";
    if (loc.includes("/#/")) {
      staging = loc.substring(0, loc.lastIndexOf("/#/"));
    }
  }
  return postRequest("/sendLoginEmail", { email, staging });
}

async function saveRecord(formInput) {
  const countryid = getCountry();
  // construct the object with form values
  const requestObj = {
    resource: formInput,
    countryid
  };
  return postRequest("/saveRecord", requestObj);
}

async function uploadNewBusiness(base64FileData) {
  return postRequest("/uploadNewBusiness", { base64FileData, countryid });
}

async function reallocateRecord(formInput) {
  // construct the object with form values and journey flag value
  const countryid = getCountry();
  const requestObj = {
    resource: formInput,
    countryid
  };
  return postRequest("/reallocateRecord", requestObj);
}

async function saveValidationsData(formInput) {
  const countryid = getCountry();
  const requestObj = {
    resource: formInput,
    countryid
  };
  return postRequest("/saveValidationsData", requestObj);
}

async function uploadBulkDataFile(fileName, base64FileData) {
  let response = bulkUpload(fileName, base64FileData, "saveBulkRecords", 1);

  return response;
}

async function uploadGlobalBulkDataFile(fileName, base64FileData) {
  let response = bulkUpload(
    fileName,
    base64FileData,
    "bulkUploadGlobalCostCardData"
  );

  return response;
}

async function uploadSmartRecruiterDataFile(fileName, base64FileData) {
  let response = bulkUpload(
    fileName,
    base64FileData,
    "bulkUploadSmartRecruiterData"
  );

  return response;
}

async function getBulkUploadTemplate(uploadType) {
  return postRequest("/getBulkUploadTemplate", {
    uploadType
  });
}

async function bulkUpload(fileName, base64FileData, handler, countryid) {
  const maxPartSize = 5e6; // 5mb
  const totalParts = Math.ceil(base64FileData.length / maxPartSize);
  const parts = Array.from(Array(totalParts)).map((_, i) =>
    base64FileData.substring(i * maxPartSize, (i + 1) * maxPartSize)
  );

  console.log(
    `Uploading file of size: ${
      base64FileData.length / 1e6
    }mb in ${totalParts} parts.`
  );

  let response = null;
  for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
    const part = parts[partNumber - 1];
    let postData = {
      fileName,
      totalParts,
      partNumber,
      base64FileData: part
    };

    postData =
      handler === "saveBulkRecords" ? { ...postData, countryid } : postData;

    response = await postRequest("/" + handler, postData);
  }
  return response;
}

export default {
  sendLoginEmail,
  validateSession,
  getValidations,
  getWorkflowList,
  getRecord,
  saveRecord,
  getRecordHistory,
  uploadNewBusiness,
  reallocateRecord,
  saveValidationsData,
  uploadBulkDataFile,
  getBulkUploadTemplate,
  uploadGlobalBulkDataFile,
  uploadSmartRecruiterDataFile
};
