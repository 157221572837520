import { Zoom } from "react-toastify";

const UI_STATE_LOADING = "LOADING";
const UI_STATE_SUCCESS = "SUCCESS";
const UI_STATE_ERROR = "ERROR";
const UI_STATE_EMPTY = "EMPTY";
const STD_MSG_ERROR = "Something Went Wrong";
const STD_MSG_EMPTY = "Not Available";
const KEY_ENTER = "Enter";
const ERR_SESSION = "Session not found";
const FINOPS_SESSION_KEY = "finopsSessionKey";
const FINOPS_LOGIN_KEY = "finopsLoginKey";
const FINOPS_LOGIN_KEY_APPEND = "finopsLoginKeyAppend";
const SOURCE_RAP_BULK = "RAP Bulk Upload";
const SOURCE_GLOBAL_COST_DATA_BULK = "Global Cost Card Upload";
const SOURCE_SMARTRECRUITER_DATA_BULK = "Smart Recruiter Bulk Upload";

const FINOPS_LOGIN_USER = "finopsLoginUser";

const TOAST_OPTIONS = {
  autoClose: 2000,
  transition: Zoom
};

const COL_NAME = "Name";
const COL_EMAIL = "Email";
const COL_STATUS = "Status";
const COL_ADMIN = "Admin";

// Environment Variables
// eslint-disable-next-line prefer-destructuring
const USER_ADMIN_ROOT = process.env.USER_ADMIN_ROOT;
const ADMIN_GROUP = "ats_admin-group";

const USER_FORM_FIELDS = {
  name: "displayName",
  email: "email",
  groups: "groups",
  countries: "countries",
  status: "enabled",
  parpermissions: "parGroup",
  summarydepartment: "summaryDepartment",
  servicelinedepartment: "serviceLineDepartment",
  profitcenter: "payrollProfitCenter",
  clientbrandlist: "clientBrandList"
};

const FINOPS_COUNTRY = "finopsCountry";
const FINOPS_MULTIPLE_COUNTRY = "finopsMultipleCountry";
const BULK_UPLOAD_TYPE = {
  rap_record_data: "rap_record_data",
  cost_card_data: "cost_card_data",
  smart_recruiter_data: "smart_recruiter_data"
};

const countryData = [
  {
    label: "Canada",
    value: {
      id: "2",
      name: "Canada"
    }
  },
  {
    label: "United States",
    value: {
      id: "1",
      name: "United States"
    }
  }
];

const FINOPS_ADMIN_MENU = [
  {
    title: "FinOps Administration",
    url: "",
    submenu: [
      {
        title: "User Manager",
        url: "/#/user-manager"
      },
      {
        title: "FinOps Permissions",
        url: "/#/finops-permissions"
      }
    ]
  }
];

export default {
  UI_STATE_LOADING,
  UI_STATE_SUCCESS,
  UI_STATE_ERROR,
  UI_STATE_EMPTY,
  TOAST_OPTIONS,
  STD_MSG_ERROR,
  STD_MSG_EMPTY,
  KEY_ENTER,
  ERR_SESSION,
  FINOPS_SESSION_KEY,
  FINOPS_LOGIN_KEY,
  FINOPS_LOGIN_KEY_APPEND,
  FINOPS_LOGIN_USER,
  COL_NAME,
  COL_EMAIL,
  COL_STATUS,
  COL_ADMIN,
  ADMIN_GROUP,
  USER_ADMIN_ROOT,
  USER_FORM_FIELDS,
  SOURCE_RAP_BULK,
  FINOPS_COUNTRY,
  FINOPS_MULTIPLE_COUNTRY,
  SOURCE_GLOBAL_COST_DATA_BULK,
  BULK_UPLOAD_TYPE,
  SOURCE_SMARTRECRUITER_DATA_BULK,
  FINOPS_ADMIN_MENU,
  countryData
};
