import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const NotificationCard = ({ item, onClose }) => {
  const navigate = useNavigate();

  const goToDetail = (parRequestId) => {
    navigate("/paredit", {
      state: { parRequestId }
    });
  };
  return (
    <div className="notification" key={item.notification_id}>
      <div className="row ">
        <div className="col-12">
          <div className="notification-content">{item.content}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 detail">
          <div>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onClose();
                goToDetail(item.par_request_id);
              }}
            >
              <span>Submission Detail</span>
            </a>
          </div>
          <div className="ml-1">|</div>
          <div className="ml-1">
            {item.created_date &&
              format(new Date(item.created_date), "hh:mm a")}
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationCard.propTypes = {
  item: PropTypes.shape({
    content: PropTypes.string,
    created_date: PropTypes.string,
    notification_id: PropTypes.number,
    par_request_id: PropTypes.string
  }),
  onClose: PropTypes.func
};

NotificationCard.defaultProps = {
  item: {
    content: null,
    created_date: null,
    notification_id: null,
    par_request_id: null
  },
  onClose: () => {}
};

export default NotificationCard;
