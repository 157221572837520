const PAR_STATUS = {
  withdraw: "Withdrawn",
  draft: "Draft",
  complete: "PAR Complete"
};

const SHOW_PER_PAGE = [100, 500, 1000, "Show All"];

const STAFF_PAGE_LOCAL_STORAGE = "staffPageSize";

const PAR_SUBMISSION_LOCAL_STORAGE = "ParPageSize";

const REQUISITION_NEW_CLIENT = "New Client (New Logo)";
const REQUISITION_PRACTICE_INVST = "Practice Investment";

const REASON_FOR_REQUISITION = [
  REQUISITION_NEW_CLIENT,
  "Active Client Incremental",
  REQUISITION_PRACTICE_INVST
];

const REASON_FOR_REQUISITION_TBH = [
  REQUISITION_NEW_CLIENT,
  "Active Client Incremental",
  "Unserviced Role",
  REQUISITION_PRACTICE_INVST,
  "Backfill Existing Position"
];

const FILL_TYPES = [
  "External Fill",
  "Internal Fill, Lateral Move",
  "Internal Fill, Promotion"
];
const PERFORMANCE_RATINGS = [
  "Exceptional",
  "Very Strong",
  "Successful",
  "Developing",
  "Lack of Performance Fit",
  "/"
];
const LOCATION_REASON = [
  "Client Location",
  "Onsite Team Location",
  "Client & Onsite Team",
  "Available Talent Market"
];

const PARTNERS_OPEN_REQ = ["DGS - India", "DGS - Phillipines", "Ugam - India"];

const PARTNERS = [...PARTNERS_OPEN_REQ, "Global Mind - Argentina"];

const FORM_FIELDS = {
  par_request_id: "PAR ID",
  current_queue_id: "Current Queue Id",
  open_requisition_reason: "Reason for Open Requisition",
  summary_department: "Summary Department",
  serviceline_department: "Service Line Department",
  par_level: "Level",
  allocations: "Allocations",
  fill_type: "Fill Type",
  fill_date: "Target Fill Date",
  hiring_manager: "Hiring Manager (Supervisory Organization)",
  justification: "Position Action Justification",
  cc_emails: "CC for PAR Notifications",
  comment: "Comments",
  employee_type: "Employee Type",
  fill_location: "Fill Location",
  location_reason: "Location Reason",
  current_performance_rating: "Current Performance Rating",
  employee_name: "employee_name",
  par_position_title: "Position Title",
  business_title: "Business Title",
  par_billable: "Billable",
  attachment_paths: "Upload Supporting Files",
  date_of_last_compensation_change: "Date Of Last Compensation Change",
  date_of_lastpromotion: "Date Of Last Promotion",
  current_base_salary: "Current Base Salary",
  employee_workdayid: "Employee Workday ID",
  employee_workdaytitle: "Employee Title",
  employee_location: "Employee Location",
  requested_offer: "Requested Offer",
  submitter: "Submitter",
  is_exempt: "Exempt",
  profit_center: "Profit Center",
  partner: "Partner",
  partner_cost: "Partner Cost",
  par_brand: "Brand",
  par_axcompany: "AX Company",
  par_payroll_company: "Payroll Company",
  par_bpc: "BPC",
  job_code: "Job Code",
  requisition_id: "Requisition ID",
  related_pars: "Related PARs"
};

const EXTERNAL_FILL_FIELDS = [
  "fill_location",
  "employee_type",
  "location_reason",
  "is_exempt",
  "partner",
  "partner_cost"
];
const INTERNAL_FILL_FIELDS = [
  "employee_name",
  "employee_type",
  "employee_workdayid",
  "employee_workdaytitle",
  "employee_location",
  "current_base_salary",
  "requested_offer",
  "date_of_last_compensation_change",
  "date_of_lastpromotion",
  "current_performance_rating"
];

const PAR_WORKFLOW = {
  OPEN_REQUISITION: "Open Requisition",
  REALLOCATION: "Reallocation",
  BACKFILL_REQUISITION: "Backfill Requisition",
  PROMOTION_IN_PLACE: "Promotion in Place",
  SALARY_ADJUSTMENT: "Salary Adjustment",
  URGENT_COUNTER_OFFER: "Urgent Counter Offer",
  OPEN_REQUISITION_TBH: "Open Requisition (TBH PIN)"
};

const PIN_STATUS = {
  TBH: "TBH",
  ACTIVE: "Active"
};

const BOOLEAN_VALUES = ["Yes", "No"];

const LIST_FIELDS = [
  "summary_department",
  "serviceline_department",
  "open_requisition_reason",
  "par_level",
  "par_request_id",
  "fill_type",
  "client_brand",
  "related_pars",
  "hiring_manager",
  "employee_type",
  "employee_name",
  "fill_location",
  "location_reason",
  "client_lob",
  "location",
  "status",
  "cc_emails",
  "current_performance_rating",
  "profit_center",
  "partner"
];

const DROPDOWN_TYPE_LIST = [
  "Location",
  "Payroll - Company",
  "Payroll - Profit Center",
  "Workday Title",
  "Summary Department",
  "Service Line Department",
  "Payroll Department",
  "Client Brand",
  "Client LOB"
];

const LIST_TYPES = {
  globalPerm: "globalPerm",
  tempContingent: "tempContingent",
  activeOptions: "activeOptions",
  otherOptions: "otherOptions"
};

const PAR_ACTIONS = {
  APPROVED: "Approved",
  APPROVED_EXCEPTION: "Approved - Exception PAR",
  APPROVED_GLOBAL: "Approved - Global Employee",
  APPROVED_BELOW_45: "Approved - Level 45 or Below",
  APPROVED_OVER_50: "Approved - Level 50 or Above",
  PAR_COMPLETE_RMACTION: "Complete - RM Action",
  NEW_PAR_SUBMITTED: "New PAR Submitted",
  PAR_COMPLETE: "PAR Complete",
  PIN_CONFIRMED_EXISTING_CLIENT: "PIN Confirmed - Existing Client",
  PIN_CONFIRMED_NEW_CLIENT: "PIN Confirmed - New Client",
  PIN_CONFIRMED_PRACTICE_INVT: "PIN Confirmed - Practice Investment",
  RETURNED: "Returned",
  RETURNED_TO_CEO: "Returned to CEO",
  WITHDRAWN: "Withdrawn"
};

const PAR_PERMISSIONS_MAPPING = {
  PAR_ScaledServiceLead: "finOps_PAR-ScaledServiceLead-userRole",
  PAR_BrandCEO: "finOps_PAR-BrandCEO-userRole",
  PAR_Finance: "finOps_PAR-Finance-userRole",
  PAR_ClientBrandSubmitter: "finOps_PAR-ClientBrandSubmitter-userRole",
  PAR_RM: "finOps_PAR-RM-userRole",
  PAR_HR: "finOps_PAR-HR-userRole",
  PAR_Executive: "finOps_PAR-Executive-userRole",
  finOps_PAR_approle: "finOps_PAR-approle"
};

const PAR_QUEUE = {
  RM_INTAKE: "RM Intake",
  RM_ACTION: "RM Action",
  HR_INTAKE: "HR Intake",
  HR_ACTION: "HR Action",
  SCALED_SVC: "Scaled Service",
  FINANCE: "Brand Finance",
  BRAND_CEO: "Brand CEO",
  EXCECUTIVE: "Executive Approval"
};

const PAR_QUEUE_PERMISSION_MAP = {
  "finOps_PAR-ScaledServiceLead-userRole": [PAR_QUEUE.SCALED_SVC],
  "finOps_PAR-BrandCEO-userRole": [PAR_QUEUE.BRAND_CEO],
  "finOps_PAR-Finance-userRole": [PAR_QUEUE.FINANCE],
  "finOps_PAR-RM-userRole": [PAR_QUEUE.RM_INTAKE, PAR_QUEUE.RM_ACTION],
  "finOps_PAR-HR-userRole": [PAR_QUEUE.HR_INTAKE, PAR_QUEUE.HR_ACTION],
  "finOps_PAR-Executive-userRole": [PAR_QUEUE.EXCECUTIVE]
};

const PARGROUPADDKEYS = [
  "user_id",
  "permission_name",
  "permission_for_field_type",
  "field_value",
  "field_type_parent",
  "field_parent_value"
];

const PAR_FIELD_NAME = {
  serviceline_department: "serviceline_department",
  profit_center: "profit_center",
  par_brand: "par_brand",
  summary_department: "summary_department"
};

// Different categories of queues used to render queue tabs
const PAR_INTAKE_QUEUE = "Intake";
const PAR_ACTION_QUEUE = "Action";
const PAR_REGULAR_QUEUE = "Regular";
const PAR_TICKET_HISTORY_COUNT = 10;

export default {
  PAR_STATUS,
  PAR_WORKFLOW,
  REASON_FOR_REQUISITION,
  FILL_TYPES,
  PERFORMANCE_RATINGS,
  LOCATION_REASON,
  FORM_FIELDS,
  LIST_FIELDS,
  DROPDOWN_TYPE_LIST,
  LIST_TYPES,
  PAR_ACTIONS,
  SHOW_PER_PAGE,
  STAFF_PAGE_LOCAL_STORAGE,
  PAR_SUBMISSION_LOCAL_STORAGE,
  PIN_STATUS,
  PAR_PERMISSIONS_MAPPING,
  PARGROUPADDKEYS,
  PAR_FIELD_NAME,
  PAR_INTAKE_QUEUE,
  PAR_ACTION_QUEUE,
  PAR_REGULAR_QUEUE,
  REASON_FOR_REQUISITION_TBH,
  REQUISITION_NEW_CLIENT,
  REQUISITION_PRACTICE_INVST,
  PAR_QUEUE,
  BOOLEAN_VALUES,
  PARTNERS_OPEN_REQ,
  PARTNERS,
  PAR_QUEUE_PERMISSION_MAP,
  EXTERNAL_FILL_FIELDS,
  INTERNAL_FILL_FIELDS,
  PAR_TICKET_HISTORY_COUNT
};
