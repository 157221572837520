/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import TypeAheadBoxField from "../../shared/TypeAheadBoxField";
import TextAreaField from "../../shared/TextAreaField";
import CollapsibleCard from "../../shared/CollapsibleCard";
import UploadFile from "../../shared/UploadFile";

const PositionJustification = ({
  formikProps,
  canModifyPARRecords,
  availablePARRequests,
  showDescription,
  showFieldsReadOnly
}) => (
  <CollapsibleCard title="Position Justification">
    <div className="row">
      <div className="mt-3 col-md-3">
        <TypeAheadBoxField
          formikProps={formikProps}
          suggestions={availablePARRequests.map((x) => x.mslPin || x)}
          fieldName="related_pars"
          label="Related PAR Ticket(s)"
          showDescription={showFieldsReadOnly.related_pars}
          isOptional
          isMultiSelect
          disable={!canModifyPARRecords}
        />
      </div>
      <div className="mt-3 col-md-3">
        <TypeAheadBoxField
          formikProps={formikProps}
          suggestions={[]}
          fieldName="cc_emails"
          label="CC for PAR Notification"
          showCreateNewOption
          isOptional
          isMultiSelect
          showDescription={showDescription}
          disable={!canModifyPARRecords}
        />
      </div>
    </div>
    <div className="row ">
      <div className="mt-3 col-md-6">
        <TextAreaField
          formikProps={formikProps}
          className="mr-0"
          label="Position Action Justification"
          fieldName="justification"
          id="justification"
          placeholder="Enter email Address"
          showDescription={showDescription}
          disabled={!canModifyPARRecords}
        />
      </div>
    </div>
    <div className="row">
      <div className="mt-3 col-md-6">
        <UploadFile
          formikProps={formikProps}
          fieldName="attachment_paths"
          label="Upload Supporting Attachment(s)"
          maxSize={25}
          fileType=".xls,.xlsx, .doc, .docx, .pdf, .jpg"
          disable={!canModifyPARRecords || showDescription}
          showDescription={showDescription}
          isOptional
        />
      </div>
    </div>
  </CollapsibleCard>
);

PositionJustification.propTypes = {
  formikProps: PropTypes.shape({
    values: PropTypes.shape({
      fill_type: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    }),
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func
  }).isRequired,
  canModifyPARRecords: PropTypes.bool,
  showDescription: PropTypes.bool,
  availablePARRequests: PropTypes.instanceOf(Array),
  showFieldsReadOnly: PropTypes.shape({
    related_pars: PropTypes.bool
  })
};

PositionJustification.defaultProps = {
  availablePARRequests: [],
  canModifyPARRecords: false,
  showDescription: false,
  showFieldsReadOnly: {}
};

export default PositionJustification;
