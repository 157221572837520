import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import StatusChip from "./StatusChip";

const TicketHistoryCard = ({ item }) => {
  const [showComments, setShowComments] = useState(false);

  return (
    <>
      <div className="ticket-history" key={item.next_queue}>
        <div className="row mb-2">
          <div className="col-5">
            <div className="action">
              <strong>Moved to {item.next_queue}</strong>
            </div>
            <div className="creater">
              <strong>By {item.created_by}</strong>
            </div>
          </div>
          <div className="col-7 text-right">
            <div className="queue">
              <span className="par-status mr-1">
                <StatusChip action={item.action} />
              </span>
              <span
                className="ml-1 expand-collapse-comment"
                onClick={() => setShowComments(!showComments)}
              >
                {showComments ? (
                  <span>
                    <i className="fa fa-minus" aria-hidden="true" />
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-plus" aria-hidden="true" />
                  </span>
                )}
              </span>
            </div>
            <div className="time mr-4 mt-1">
              {item.created_date &&
                format(new Date(item.created_date), "hh:mm a")}
            </div>
          </div>
        </div>
        {showComments && (
          <div className="row comment-container">
            <div className="col-12 comment ">
              <div className="comment-text pt-2">
                {item.comment || "No comments"}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

TicketHistoryCard.propTypes = {
  item: PropTypes.shape({
    action: PropTypes.string,
    current_queue: PropTypes.string,
    next_queue: PropTypes.string,
    created_date: PropTypes.string,
    created_by: PropTypes.string,
    comment: PropTypes.string
  })
};

TicketHistoryCard.defaultProps = {
  item: {
    action: null,
    current_queue: null,
    next_queue: null,
    created_date: null,
    created_by: null,
    comment: null
  }
};

export default TicketHistoryCard;
